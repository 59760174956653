import { useState, useRef } from "react"
import { Wheel } from 'react-custom-roulette'
import { WheelData } from '../../node_modules/react-custom-roulette/dist/components/Wheel/types'
// import 'bootstrap/dist/css/bootstrap.css';
// import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import base from '../assets/img/base.png'
import centro from '../assets/img/centro.png'
import pestaña from '../assets/img/pestaña.png'
import buttonImg from '../assets/img/boton.png'
import buttonImg2 from '../assets/img/boton2.png'
import bebida from '../assets/img/bebida.png'
import descuento10 from '../assets/img/papas guapas sin cortar.png'
import descuento5 from '../assets/img/papas guapas cortadas.png'
import lose from '../assets/img/lose.png'
import papas from '../assets/img/papas fritas cortada.png'
import premio from '../assets/img/papas fritas con punta.png'
import papasG from '../assets/img/papasG.png'
import btnBack from '../assets/img/undo-alt-solid.svg'
import btnFullScreen from '../assets/img/expand-solid.svg'
import bigtoast from '../assets/img/big tost blanco.png'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

const data: WheelData[] = [
    { image: { uri: premio, sizeMultiplier: 2 }, style: { backgroundColor: "#ffd500" } },
    { image: { uri: lose, sizeMultiplier: 2 } },
    { image: { uri: descuento10, sizeMultiplier: 2 }, style: { backgroundColor: "#fff" } },
    { image: { uri: lose, sizeMultiplier: 2 } },
    { image: { uri: descuento5, sizeMultiplier: 2 }, style: { backgroundColor: "#fff" } },
    { image: { uri: lose, sizeMultiplier: 2 } },
    { image: { uri: descuento10, sizeMultiplier: 2 }, style: { backgroundColor: "#fff" } },
    { image: { uri: lose, sizeMultiplier: 2 } },
    { image: { uri: descuento5, sizeMultiplier: 2 }, style: { backgroundColor: "#fff" } },
    { image: { uri: lose, sizeMultiplier: 2 } },
    { image: { uri: descuento5, sizeMultiplier: 2 }, style: { backgroundColor: "#fff" } },
    { image: { uri: lose, sizeMultiplier: 2 } }
]


export default function Roulette() {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [modal, setModal] = useState(false);    

    const toggle = () => setModal(!modal);
    const [styleBase, setStyle] = useState("imgBaseStyle");
    const [styleCentro, setStyle2] = useState("imgCentroStyle");
    const [showBtn, setStyle3] = useState(buttonImg);

    const Pointer = {
        src: pestaña,
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    const launchFullScreen = () =>{
        
        const docWithBrowsersExitFunctions = document as Document & {
            mozCancelFullScreen(): Promise<void>;
            webkitExitFullscreen(): Promise<void>;
            msExitFullscreen(): Promise<void>;
        };
         
        let modo = window.innerHeight;
        const element = document.documentElement
        console.log(modo,"modo")
        var width = window.innerWidth * window.devicePixelRatio;
        var height = window.innerHeight * window.devicePixelRatio;
        console.log(width,height,"jeje")
        
        if (modo < 930) {        
            console.log("1")
            element.requestFullscreen();
            setStyle("imgBaseStyleMAX");
            setStyle2("imgCentroStyleMAX");
        } else if (modo == 1080){
            console.log("2")
            docWithBrowsersExitFunctions.webkitExitFullscreen();
            setStyle("imgBaseStyle");
            setStyle2("imgCentroStyle");
        } else if (height > 1080){
            console.log("3")
            docWithBrowsersExitFunctions.webkitExitFullscreen();
            setStyle("imgBaseStyle4K");
            setStyle2("imgCentroStyle4K");
        } else if (height > 2000){
            console.log("4")
            docWithBrowsersExitFunctions.webkitExitFullscreen();
            setStyle("imgBaseStyle4KMAX");
            setStyle2("imgCentroStyle4KMAX");
        }
    }

    const [msg, setMensaje] = useState("");
    const ganador = useRef(false);

    /////////FUNCION PARA MOSTRAR MENSAJE CON INFO DEL RESULTADO ( NO SE USA PERO FUNCIONA) ///////
    function verPremio(number: number) {
        return new Promise((resolve) => {
          setTimeout(() => {
            if (number % 2 == 0){                
                console.log(number % 2,"resto")
                if (number == 2 || number == 6){
                    ganador.current = true;
                    setMensaje("Ganaste 10% de descuento!")
                }
                if (number == 4 || number == 8 || number == 10){
                    ganador.current = true;
                    setMensaje("Ganaste 5% de descuento!")
                }   
                if (number == 0){
                    ganador.current = true;
                    setMensaje("Sos el ganador del premio mayor!")
                }                   
            }else{
                ganador.current = false;
                setMensaje("Mejor suerte la próxima!")
            }      
            resolve(ganador)      
          }, 2000);
        });
    }
    ////////////////////////

    const handleSpinClick = async() => {
         if (!mustSpin) {

            const newPrizeNumber = Math.floor(Math.random() * data.length);           
            setPrizeNumber(newPrizeNumber);
            setStyle3(buttonImg2);
            setMustSpin(true);  

            /////////PARTE DE FUNCIONALIDAD PARA MOSTRAR MENSAJE CON INFO DEL RESULTADO ( NO SE USA PERO FUNCIONA) ///////
            // await verPremio(newPrizeNumber);  
            // if (ganador.current){
            //     handleClickOpen();
            // }   
            ////////////////               
        }
    }
    
    return (
        <div className="ruletaContainer">
            {/* <a id="buttonJuga" onClick={goToForm} ><img src={btnBack} className='imgBack' /></a> */}
            <a id="buttonFull" onClick={launchFullScreen} ><img alt="" src={btnFullScreen} className='imgFullScreen' /></a>            
            <img alt="" src={base} className={styleBase} />
            <img alt="" src={centro} className={styleCentro} />
            <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                backgroundColors={['#a9518f', '#713882']}
                textColors={['#ffffff', '#a9518f', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                outerBorderWidth={0}
                radiusLineWidth={0}
                innerRadius={20}
                fontSize={14}
                textDistance={65}
                spinDuration={0.3}
                pointerProps={Pointer}
                perpendicularText={true}
                onStopSpinning={() => {                    
                     setMustSpin(false);
                     toggle();   
                     setStyle3(buttonImg);                 
                }}
            />

        <a id="buttonJuga" onClick={handleSpinClick}><img src={showBtn} alt="" style={{ width: 319 }} /></a>    
           {/* MODAL PARA MOSTRAR RESULTADO NO SE USA PERO FUNCIONA */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"md"}
            >
                <DialogTitle id="alert-dialog-title" className="text-black-300">
                    Tenemos un ganador!
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description"> 
                    {msg}                   
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button size="lg" variant="primary" onClick={handleClose} autoFocus>
                    Aceptar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
                    
    )
}

