import Roulette from './screen/roulette';
import './App.css';


function App() {
  return (
    <div className="App">
      <Roulette></Roulette>
    </div>
  );
}

export default App;
